import axios from 'axios'

 const GOOGLE_FORM_NAME_ID = 'entry.662041051'
 const GOOGLE_FORM_EMAIL_ID = 'entry.1745374150'//'YPqjbf'
 const GOOGLE_FORM_PHONE_NUMBER = 'entry.1006968047'
 const GOOGLE_FORM_QUERY='entry.994522266'
 const GOOGLE_FORM_ACTION_URL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdfDxme7_ZTur-4KXHsf-7Vc6IWCC-qDjZ9XtOR4pakdijbOw/formResponse'


export const sendResponse = (name, email, number, query, page) =>{

    const formData = new FormData()
    formData.append(GOOGLE_FORM_NAME_ID, name)
    formData.append(GOOGLE_FORM_EMAIL_ID, email)
    formData.append(GOOGLE_FORM_PHONE_NUMBER, number)

    if(page==='home'){
        formData.append(GOOGLE_FORM_QUERY, query)
    }
    else{
        formData.append(GOOGLE_FORM_QUERY, 'NA')
    }
    axios.post(GOOGLE_FORM_ACTION_URL, formData)
        .then(() => {
        }).catch((err) => {
        })

}