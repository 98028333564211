import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <div className='footer'>
            <h5>Legal Disclaimer</h5>
            <p>The information contained on this website merely provides details of our firm to persons who have shown interest in knowing more about us and is not intended to solicit work or advertise our capabilities in any manner. The information provided on this website is general in nature and should not be used as a basis of decision-making without further professional advice. The third party site links are only provided for ready reference of the users and Devinidhi Arya & Associates neither controls their content nor undertakes any responsibility regarding them.</p>
            <div className='copyright'>
                <h5>&#169; {`${new Date().getFullYear()}`} www.cadev.in : All Rights Reserved</h5>
            </div>

        </div>
    )
}

export default Footer