import React from 'react';
import '../../views/Home/Home.css'

const MediaContainer = () => {
    return (
        <div className='media-container'>

            <h1>media center</h1>
            <hr />
            <div className='mediaTiles'>
                <section data-aos='fade-right'>
                    <p>Publications</p>
                </section>
                <section data-aos='fade-left' data-aos-delay="100" >
                    <p>Press Realeases</p>

                </section>
                <section data-aos='fade-left' data-aos-delay="250">
                    <p>Newsletters</p>

                </section>
            </div>
        </div>
    );
};

export default MediaContainer;