import React, { useEffect } from 'react'
import './Home.css'
import { Icon } from 'react-icons-kit'
// import { twitterSquare } from 'react-icons-kit/fa/twitterSquare'
// import { facebookSquare } from 'react-icons-kit/fa/facebookSquare'
// import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare'
// import { mobilePhone } from 'react-icons-kit/fa/mobilePhone'
import { thumbsUp } from 'react-icons-kit/fa/thumbsUp'
import { suitcase } from 'react-icons-kit/fa/suitcase'
import { child } from 'react-icons-kit/fa/child'
import { ic_build } from 'react-icons-kit/md/ic_build'
import {
    useHistory
} from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import MediaContainer from '../../components/MediaContainer/MediaContainer'
import Form from '../../components/Form/Form'

AOS.init();

const Home = () => {

    const navigate = (name) => {
        history.push({
            pathname: '/service',
            name: name
        })
    }
    const history = useHistory()

    useEffect(() => {

    }, [])
    return (
        <div className='homepage'>
            <div className="img-slider">
                <div className="slider-container">

                    <div className="slide">
                        <img alt='' src="https://images.unsplash.com/photo-1598432439250-0330f9130e14?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" />
                    </div>

                    <div className="slide">
                        <img alt='' src="https://images.unsplash.com/photo-1597781725069-16d5ca74e6d4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" />
                    </div>

                    <div className="slide">
                        <img alt='' src="https://images.unsplash.com/photo-1565373679580-fc0cb538f49c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" />
                    </div>

                </div>
            </div>
            <div className="welcome-section">
                {/* <Icon icon={twitterSquare} className='twitter' size={50}></Icon>
                <Icon icon={facebookSquare} className='facebook' size={50}></Icon>
                <Icon icon={linkedinSquare} className='linkedin' size={50}></Icon>
                <button className='phoneNumbertile'> <Icon icon={mobilePhone} className='' size={30}></Icon> 9999999999</button> */}

                <div className="welcome-text">
                    <p> WELCOME</p>
                    <p>Devnidhi Arya & Associates, (DAA) a professional Chartered Accountancy firm, makes complex simple. We are a tax advisory, business entry and consulting service provider. We combine the efficiency and technical expertise with the intellectual and personal skills required to build close and successful client relationships. Our team consists of multi-disciplinary professionals like Chartered Accountants, Lawyers, Company Secretaries amongst others. Our strong commitment drives record accountant pro-bono work.</p>
                </div>
            </div>

            <div className='services-container'>
                <div className='service-divider-one' >
                    <section data-aos='fade-right' data-aos-delay="0" onClick={() => navigate('fm')}>
                        <Icon icon={thumbsUp} className='service-cintainer-icon' size={40}></Icon>
                        <h3>Financial Matters</h3>
                        <p>We are actively engaged in statutory and tax audits, Internal audits, Revenue audits, Forensic audits, Audit support, Review of financial statemnents, Preparation and implementaion of policies, Standard operating procedures (SOPs) and Risk management.</p>
                    </section>
                    <section data-aos='fade-down' data-aos-delay="0" onClick={() => navigate('aa')}>
                        <Icon icon={ic_build} className='service-cintainer-icon' size={40}></Icon>
                        <h3>Implementation of IND-AS</h3>
                        <p>We provide services in first time implementaion and adoption of balance sheet and financial data as per IND-AS requirements through strategic assessment and audit plan.</p>
                    </section>
                    <section data-aos='fade-up' data-aos-delay="0" onClick={() => navigate('it')}>
                        <Icon icon={suitcase} className='service-cintainer-icon' size={40}></Icon>
                        <h3>Taxation Services</h3>
                        <p> We provide consultancy and legal services with regards to Direct Tax laws in India(CIT(A), ITAT, DRT). We also assist in tax compliances realted to GST. Also provide services in the field of Transfer pricing and International taxation</p>
                    </section>
                </div>
                <div className='service-divider-two'>
                    <section data-aos='fade-right' data-aos-delay="300" onClick={() => navigate('acc')}>
                        <Icon icon={child} className='service-cintainer-icon' size={40}></Icon>
                        <h3>Start-Ups</h3>
                        <p>We assist our clients in identifying the right business structure, how to obtain business licence, incorporation of company and LLP and setting up of policies and procedures. We also provide secetarial and legal support, Due diligence, Management consulting and fund management.   </p>

                    </section>
                    <section data-aos='fade-down' data-aos-delay="400" onClick={() => navigate('cl')}>
                        <Icon icon={child} className='service-cintainer-icon' size={40}></Icon>
                        <h3>Business Advisory and Re-structuring services</h3>
                        <p>We provide services in relation to mergers and aquisitions, Demergers, Amalgamation, Capital re-structuring, Joint-ventures and stragtegic alliances.</p>

                    </section>
                    <section data-aos='fade-left' data-aos-delay="500" onClick={() => navigate('rs')}>
                        <Icon icon={child} className='service-cintainer-icon' size={40}></Icon>
                        <h3>Legal Compliances</h3>
                        <p>We have a separte team of professionals who help in legal and litigation services related to corporate laws and Insolvancy laws. Also actively involved in providing legal assitance in Sarfaesi Act through DRT </p>

                    </section>
                </div>
            </div>
            <MediaContainer />
            <Form />
        </div>
    )

}

export default Home