import React, { useEffect } from 'react';
import './ContactUs.css'
import { mobilePhone } from 'react-icons-kit/fa/mobilePhone'
import { Icon } from 'react-icons-kit'
import { ic_email } from 'react-icons-kit/md/ic_email'
import { ic_room } from 'react-icons-kit/md/ic_room'
import MediaContainer from '../../components/MediaContainer/MediaContainer'
import Form from '../../components/Form/Form'

const ContactUs = () => {
    useEffect(() => {
        return () => { }
    }, [])

    const officeDetails = [{
        name: 'Corporate Office',
        "number": '+91-7982639339 , 011-43215950',
        address: '308-310 Agarwal chamber II Veer sawarkar block, New Delhi, Delhi 110092'
    }]
    return (
        <div className='contactUsPage'>
            <div className='contactUsPageImage'>
                <div className='contactUsContainer'>

                    <div className='contactUsTile'>
                        <div className='child'>
                            <Icon icon={ic_email} className='contactUsPageIcon' size={60}></Icon>

                        </div>

                        <h3>Contact by Email</h3>
                        <p>
                            Look forward to your mail at devaryafca@.com &ensp; accounts@cadev.in &ensp; daryaassociates@gmail.com<br /> Expect us to respond with in 24 hours.</p>
                    </div>

                    <div className='contactUsTile'>
                        <div className='child'>
                            <Icon icon={mobilePhone} className='contactUsPageIcon' size={60}></Icon>
                        </div>
                        <h3>Contact by Phone</h3>
                        <p>Dial +91-9818125950&ensp;+91-7982639339 <br /> 011-43215950&ensp;011-41017879 to reach us instantly</p>

                    </div>

                    <div className='contactUsTile'>
                        <div className='child'>
                            <Icon icon={ic_room} className='contactUsPageIcon' size={60}></Icon>

                        </div>
                        <h3>Come to see us</h3>
                        <p>We are well connected through metro. The nearest metro station is Nirman Vihar, New Delhi. Look forward to see you!</p>

                    </div>
                </div>
            </div>

            <div
                style={{
                    height: '60vh',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                <h1> Branch/Head-Office</h1>
                <hr />
                {
                    officeDetails.map((x, index) => {
                        return (
                            <div style={{ height: '50%', width: '25%', marginTop: '1rem' }}
                                className='officeDetail'
                                key={`${x.name}${index}`}
                            >
                                <h3 style={{ textAlign: 'center' }}>{x.name}</h3>
                                <span style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                    <Icon icon={mobilePhone} style={{ color: 'black' }} size={30}></Icon>
                                    {x.number}
                                </span>
                                <span style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                    <Icon icon={ic_room} style={{ color: 'black' }} size={30}></Icon>
                                    {x.address}
                                </span>
                            </div>
                        )
                    })
                }
            </div>
            <MediaContainer />
            <Form />

        </div>
    );
};

export default ContactUs;