
import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Header from './components/Header/Header'

const hist = createBrowserHistory()

function App() {
  return (
    <div className="App">

      <BrowserRouter history={hist}>
        <Switch>
          <Route path="/" component={Header} />
        </Switch>
      </BrowserRouter>
      {/* <Header />
      <Home /> */}
    </div>
  );
}

export default App;
