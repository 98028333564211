import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../views/Home/Home'
import Loader from '../Loader/Loader'
import { sendResponse } from './helper';
import { nameValidator, numberValidator, emailVaidator, queryValidator } from './util'

const Form = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [query, setQuery] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    useEffect(() => {

    }, [])
    const handleSubmit = (event) => {
        event.preventDefault()

        const nameError = nameValidator(name)
        const emailError = emailVaidator(email)
        const numberError = numberValidator(number)
        const queryError = queryValidator(query)

        nameError && toast.error(nameError)
        emailError && toast.error(emailError)
        numberError && toast.error(numberError)
        queryError && toast.error(queryError)

        if (!nameError && !emailError && !numberError && !queryError) {
            setShowLoader(true)

            //other stuff
            sendResponse(name, email, number, query, 'home')

            setTimeout(() => {
                setShowLoader(false)
                setName('')
                setEmail('')
                setNumber('')
                setQuery('')
                toast.success("Response recorded successfully", {
                    position: 'top-right'
                })
            }, 5000)
        }

    }

    return (
        <div className='form-container'>
            <div className='form-child' >
                <label data-aos='fade-right' data-aos-delay="200" >Contact</label>
                <p data-aos='fade-right' data-aos-delay="200" >GET IN TOUCH</p>

                <Loader visible={showLoader} />
                <ToastContainer position='bottom-left' hideProgressBar='true' type='error' closeButton='false' />

                <div className='form-detail-section'>

                    <div className='form-address-details'>

                    </div>

                    <div className='form-filling' data-aos='fade-left' data-aos-delay="400">
                        <section className='enquiry-form-inputs'>
                            <input placeholder='Enter Name' value={name} onChange={event => setName(event.target.value)}  ></input>
                            <input placeholder='Enter Mobile Number' value={number} onChange={event => setNumber(event.target.value)}></input>
                        </section>
                        <input className='mobileNumberInput' placeholder='Enter e-mail' value={email} onChange={event => setEmail(event.target.value)}></input>
                        <textarea value={query} onChange={(event) => setQuery(event.target.value)} className='enquiry-form-textarea' placeholder="Type your message" ></textarea>
                        <button onClick={(event) => handleSubmit(event)}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Form;