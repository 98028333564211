import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify';
import './Services.css'
import Form from '../../components/Form/Form'
import { useLocation } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_thumb_up } from 'react-icons-kit/md/ic_thumb_up'
import { ic_verified_user } from 'react-icons-kit/md/ic_verified_user'
import { ic_directions_walk } from 'react-icons-kit/md/ic_directions_walk'
import { ic_vibration } from 'react-icons-kit/md/ic_vibration'
import { config, ServiceData } from '../../ServiceData'
import MediaContainer from '../../components/MediaContainer/MediaContainer'
// import Loader from '../../components/Loader/Loader'
// import { sendResponse } from '../../components/Form/helper';
// import { nameValidator, numberValidator, emailVaidator } from '../../components/Form/util'

const Services = () => {

    const location = useLocation()
    const [serviceName, setServiceName] = useState('acc')
    const [data, setData] = useState(ServiceData['acc'])
    // const [name, setName] = useState('')
    // const [email, setEmail] = useState('')
    // const [number, setNumber] = useState('')
    // const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        if (location.name !== undefined) {
            setServiceName(location.name)
            setData(ServiceData[location.name])
        }
    }, [location.name])


    // const handleSubmit = (event) => {
    //     event.preventDefault()

    //     const nameError = nameValidator(name)
    //     const emailError = emailVaidator(email)
    //     const numberError = numberValidator(number)

    //     nameError && toast.error(nameError)
    //     emailError && toast.error(emailError)
    //     numberError && toast.error(numberError)

    //     if (!nameError && !emailError && !numberError) {
    //         setShowLoader(true)

    //         //other stuff
    //         sendResponse(name, email, number, 'service')

    //         setTimeout(() => {
    //             setShowLoader(false)
    //             setName('')
    //             setEmail('')
    //             setNumber('')
    //             toast.success("Response recorded successfully", {
    //                 position: 'top-right'
    //             })
    //         }, 5000)
    //     }

    // }
    return (
        <div className='service-page'>
            {/* <div className='service-form-container-image'> */}
            {/* <Loader visible={showLoader} /> */}
            <ToastContainer position='bottom-left' hideProgressBar='true' type='error' closeButton='false' />
            {/* <div className='service-form-container'>
                    <div className='service-form-container-2'>
                        <div>
                            <h1>
                                OUR SERVICES
                            </h1>
                            <p>We believe in rendering services of the highest professional standards, especially by

                                ensuring close co-ordination between the partners and the client’s operations to identify the client needs and ensure timely and enable immediate action. Our clients range from limited companies and corporations to individuals, private companies, charitable and other philanthropic organizations. They range in size from medium-sized owner managed businesses to multinational corporations. We are experts in setting up business structures in and outside India with complete services including compliances of the law of the land of India.</p>

                        </div>
                        <div>
                            <h2>Money back gurantee</h2>
                            <h2>hassle free service</h2>

                            <input placeholder='Enter Full Name' value={name} onChange={event => setName(event.target.value)}></input>
                            <input placeholder='Enter email-id' value={email} onChange={event => setEmail(event.target.value)}></input>
                            <input placeholder='Enter Phone number' value={number} onChange={event => setNumber(event.target.value)}></input>
                            <button onClick={(event) => handleSubmit(event)}>GET STARTED</button>
                        </div>
                    </div>
                </div>
            </div> */}
            <Form />
            <div
                style={{
                    width: '85%',
                    height: 'auto',
                    margin: 'auto'
                }}
            >
                <h1 style={{ marginTop: '2rem', textTransform: 'uppercase', fontFamily: 'cursive' }}>
                    {
                        config[serviceName]
                    }
                </h1>
                <hr style={{ width: '100%' }} />

                {data.Subheading.length ?
                    <h3
                        style={{ textAlign: 'left' }}
                    >{data.Subheading}</h3> : null}

                {
                    data.value.map((x, index) => {
                        if (typeof x !== 'string') {
                            return (
                                <div
                                    style={{ margin: '1rem 0' }}
                                    key={`${index}${x.name}`}
                                >
                                    <h2
                                        style={{ textAlign: 'left', textDecoration: 'underline' }}
                                    >{x.name}</h2>
                                    {x.Subheading.length ? <h3
                                        style={{ textAlign: 'left', marginLeft: '1rem' }}
                                    >{x.Subheading}</h3> : null}
                                    {
                                        x.value.map(y => {
                                            return <li
                                                key={`${y}${index}`}
                                                style={{ textAlign: 'left', marginTop: '1.5rem', fontSize: '20px', marginLeft: '2rem' }}
                                            >{y}</li>
                                        })
                                    }
                                </div>
                            )

                        }

                        return (
                            <li
                                style={{ textAlign: 'left', marginTop: '1.5rem', fontSize: '20px' }}
                                key={`${index}${x}`}
                            >{x}</li>
                        )
                    })
                }
            </div>

            <div className='stepscontainer'>
                <div className='stepsDiv'>
                    <Icon icon={ic_verified_user} className='stepsIcon' size={100}></Icon>
                    <h2>Step 1</h2>
                    <p>Fill the form with details mentioned on the website</p>
                </div>
                <div className='stepsDiv'>
                    <Icon icon={ic_vibration} className='stepsIcon' size={100}></Icon>
                    <h2>Step 2</h2>
                    <p>The executive from our firm will contact you and schedule an appointment with our associates.</p>
                </div>
                <div className='stepsDiv'>
                    <Icon icon={ic_directions_walk} className='stepsIcon' size={100}></Icon>
                    <h2>Step 3</h2>
                    <p>Visit the nearest branch on the scheduled appointment date and time. Discuss about the case.</p>
                </div>
                <div className='stepsDiv'>
                    <Icon icon={ic_thumb_up} className='stepsIcon' size={100}></Icon>
                    <h2>Step 4</h2>
                    <p>The associates will complete you work within the given date.</p>
                </div>
            </div>
            <MediaContainer />
        </div>
    )
}

export default Services;