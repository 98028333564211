import Home from './views/Home/Home'
import Services from './views/Services/Sevices'
import ContactUs from './views/ContactUs/ContactUs'
import AboutUs from './views/AboutUs/AboutUs'

const dashboardRoutes = [
    {
        path: "/home",
        name: "Home",
        component: Home,
    },
    {
        path: "/service",
        name: "Service",
        component: Services,
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
    }
];

export default dashboardRoutes;
