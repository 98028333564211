import React, { useEffect } from 'react'
import './AboutUs.css'
import Calogo from '../../assests/img/caLogo.png'
import Form from '../../components/Form/Form'


const AboutUs = () => {

    useEffect(() => {
        return () => { }
    }, [])

    const partners = [{
        name: 'Devnidhi Arya',
        qual: '(FCA, Insolvency Professional, CCA, CCAM)',
        desc: 'Possesses more than 17 years of experience in audits, including statutory audits, Internal Audits, System Audits, Due Diligences, and corporate advisory transactions. He was a Member to technical research study group of Corporate Affairs (Company Law). He specializes in project financing, financial and business valuations. His forte lies in incubating every business model and exploring a suitable financial assistance. He handles Income Tax Matters, Representation with authorities and consulting in direct tax matters with CIT (A), ITA tribunal and company law matters representing in NCLT, NCLAT (cases related to oppression & mismanagement), Insolvency, mergers and liquidation'
    },
    {
        name: 'Amit Kumar',
        qual: '(FCA, DISA, CFA)',
        desc: 'Partner of the firm. He has over 5 years of experience in Preparation of income tax appeals, paper books including research on case laws, drafting of statement of facts and grounds of appeals , audits and standard operating procedures. Moreover he is handling matters in the areas of IND-AS implementations and Forensic audits.'
    },
    {
        name: 'CA Rajkumar Garg',
        qual: '(FCA)',
        desc: 'Possesses more than 11 years of experience in the field of statutory audits, internal audits and direct and indirect taxation. Has excellent exposure and expertise in implemenation of IND-AS and drafting of leagl agreements and SOPs.'
    },
    {
        name: 'Shweta Gupta',
        qual: '(FCA)',
        desc: 'Possesses more than 10 years of experience in the audits and taxations. She expertise in providing assistance for establishing offshore companies and new businesses in India.'
    },
    {
        name: 'Sachin Bansal',
        qual: '(FCA)',
        desc: 'Possesses 17 years of experience in Credit appraisal & credit operations, Risk management credit, operational, Financial, project finance, AML, capital market, investments ,portfolio , internal control procedures , audit and direct taxation. He specializes in matters of finalization of balance sheets, implementation of IND AS,GST and other core areas of accountancy and audits.'
    }
    ]

    const staff = [
        {
            name: 'Mansij Arya',
            qual: 'LLB, Insolvancy Professional and Company Secretary'
        },
        {
            name: 'Sudhanshu Gupta',
            qual: 'Insolvancy Professional and Company Secretary'
        },
        {
            name: 'Surabhi Gupta',
            qual: 'LLB'
        },
        {
            name: 'Ritu Jain',
            qual: 'Company Secretary , LLB'
        }
    ]
    return (
        <div className='aboutUsPage'>

            <div className='aboutuspageimage'>

                <h1>About DA&A</h1>
                <p>We are the modern CAs, who are digital savvy, critical thinkers and have a high emotional intelligence</p>

            </div>
            <div style={{
                width: '100%',
                height: '50vh',
                display: 'flex',
                justifyContent: 'space-around',
                alignContent: 'center',
                flexDirection: 'column'
            }}>

                <h1 style={{
                    textDecoration: 'underline',
                    fontFamily: 'sans-serif'
                }} >DEVNIDHI ARYA AND ASSOCIATES</h1>
                <div style={{
                    width: '100%',
                    height: '60%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                }}>
                    <span style={{
                        width: '60%',
                        height: '100%',
                        fontFamily: 'sans-serif',
                        fontSize: '20px',
                        textAlign: 'left'
                    }}>We are a firm of Chartered Accountants and also having association with the Company Secretaries & Lawyers with a broad vision & mission specializing in Company Law matters & Approval services from ROC, RD, MCA, RBI, FIPB, Legal matters, Taxation, Project Finance, Management services & consultancy in corporate laws. Firm is committed to provide high end services to the clients with a mix of highly skilled and experienced team.<br /><strong> We are also known as a new ally for the start ups</strong></span>
                    <span style={{
                        width: '30%',
                        height: '100%',
                    }}>
                        <img width={200} alt="" src={Calogo}></img>
                    </span>
                </div>
            </div>
            <hr />

            <div style={{ minHeight: '30vh', height: 'max-content' }}>
                <label style={{ fontSize: '24px' }} ><strong>Empanelment and Accrediation</strong></label>
                <ul style={{ listStyle: 'none', textAlign: 'left', marginLeft: '2rem', fontSize: '16px', lineHeight: '24px' }} >
                    <li>Register with C&AD Audits</li>
                    <li>Register with RBI for statutory audits of Nationalised Banks</li>
                    <li>Peer Review certified firm from Peer Review Board of India</li>
                    <li>Empaneled with IBBI as Insolvancy Professional and Liquidator</li>
                    <li>Empaneled with Co-operative Socities of Delhi</li>
                    <li>Empaneled with various Nationalised Banks as Concurrent auditors</li>
                    <li>Empaneled with SEBI as Administrator under AAPR Regulations</li>
                    <li>Forensic Audits and Fraud Detection from ICAI</li>
                    <li>Certified Arbitration, Mediation and Consiliation from ICAI</li>
                </ul>
            </div>

            <hr />
            <div
                style={{ width: '95%', minHeight: '110vh', margin: 'auto', height: 'max-content' }}
            >
                <h1 style={{
                    textDecoration: 'underline',
                    fontFamily: 'sans-serif'
                }} >Partners</h1>

                {
                    partners.map((partner, index) => {
                        return <div style={{ border: '1px solid grey', margin: '5px', padding: '5px' }}
                            key={`${index}${partner.name}`}
                        >
                            <div
                                style={{ display: 'flex', width: '80%' }}
                            >
                                <h3>{index + 1})&ensp;{partner.name}&ensp; {partner.qual}</h3>
                            </div>
                            <div style={{ textAlign: 'left', fontFamily: 'sans-serif', fontSize: '18px' }}>{partner.desc}</div>
                        </div>
                    })
                }

            </div>
            {/* <hr></hr> */}
            <div
                style={{ width: '95%', height: '45vh', margin: 'auto' }}
            >
                <h1 style={{
                    textDecoration: 'underline',
                    fontFamily: 'sans-serif',
                    marginBottom: '5%'
                }} >Our Associates & Professional Staff</h1>

                <ol
                    style={{ fontWeight: 'bold', fontSize: '20px' }}
                >
                    {
                        staff.map((x, index) => {

                            if (x.qual === '') {
                                return <li
                                    style={{
                                        textAlign: 'left',
                                        margin: '5px'

                                    }}
                                    key={`${index}${x.name}`}
                                >

                                    {x.name}
                                </li>
                            }
                            return <li
                                style={{
                                    textAlign: 'left',
                                    margin: '5px'
                                }}
                                key={`${index}${x.name}`}
                            >
                                {x.name}&nbsp;-&ensp;{x.qual}
                            </li>
                        }
                        )
                    }
                </ol>
            </div>
            <Form />
        </div>
    )
}

export default AboutUs