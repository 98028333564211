
export const nameValidator = (name) =>{
    const regex = /^[a-z A-Z]*$/
    if(!name.length){
        return 'Please Enter Name'
    }

    if(!regex.test(name)){
        return 'Please Enter Proper Name'
    }

    return ''
}

export const numberValidator = (number) =>{
    const regex = /^[0-9]*$/
    if(!number.length) {
        return 'Please Enter Number'
    }

    else if(number.length!==10){
        return 'Please Enter Proper Number'
    }

    else if(!regex.test(number)){
        return 'Please Enter Proper Number'
    }

    return ''
}

export const emailVaidator = (email) =>{
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(!email.length){
        return 'Please Enter E-mail'
    }

    if(!regex.test(email)){
        return 'Please Enter a Valid E-mail'
    }

    return ''
}

export const queryValidator = (query) =>{
    if(!query.length){
        return 'Please Enter Query'
    }

    return ''
}

