import React, { useEffect, useRef } from 'react'
import {
    useHistory, useLocation
} from 'react-router-dom'
import logo from '../../assests/img/daalogo.png';
import './Header.css';
import { Icon } from 'react-icons-kit'
import { Switch, Route, Redirect } from "react-router-dom";
import { home } from 'react-icons-kit/fa/home'
import { user } from 'react-icons-kit/fa/user'
import { ic_build } from 'react-icons-kit/md/ic_build'
import { film } from 'react-icons-kit/fa/film'
import { book } from 'react-icons-kit/fa/book'
import routes from '../../route';
import Footer from '../Footer/Footer'
import { twitterSquare } from 'react-icons-kit/fa/twitterSquare'
import { facebookSquare } from 'react-icons-kit/fa/facebookSquare'
import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare'

const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            return (
                <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                />
            );
        })}
        <Redirect from="/" to="/home" />
    </Switch>
);


function Header() {
    const resultRef = useRef();
    const location = useLocation()

    useEffect(() => {

        if (resultRef.current) {
            window.scrollTo({
                behavior: "auto",
                top: 0
            });
        }
    }, [location])

    const navigate = (name) => {
        history.push({
            pathname: '/service',
            name: name
        })
    }
    const history = useHistory()
    return (
        <div className="App" ref={resultRef}>
            <header className="App-header">
                <div className="logo-header">
                    <section className="logo-text">
                        <img src={logo} className="App-logo " height="100" width="100" alt="" />
                        <section>
                            <h1 className="name">Devnidhi Arya & Associates</h1>
                            <p className="firm">Chartered Accountant</p>
                            {/* <p className="numberISO">An ISO 9001:2008 Certified Firm</p> */}
                        </section>
                    </section>
                    <div className="icons-routes">
                        <div onClick={() => history.push('/home')}>
                            <Icon icon={home} className="home" size={30}></Icon>
                            Home
                        </div>
                        <div onClick={() => history.push('/about-us')}>
                            <Icon icon={user} className="home" size={30}></Icon>
                            About us
                        </div>
                        <div className="dropdown">
                            <Icon icon={ic_build} className="home" size={30}></Icon>
                            Services

                            <div className="dropdown-content">
                                <span onClick={() => navigate('fm')}>Financial Matters</span>
                                <span onClick={() => navigate('aa')}>Implementation of IND-AS</span>
                                <span onClick={() => navigate('it')}>Taxation Services</span>
                                <span onClick={() => navigate('acc')}>Start-Ups</span>
                                <span onClick={() => navigate('cl')}>Business and Advisory services</span>
                                <span onClick={() => navigate('rs')}>Legal Compliances</span>
                            </div>
                        </div>

                        <div className="dropdown">
                            <Icon icon={film} className="home" size={30}></Icon>
                            Media Center

                            <div className="dropdown-content-media">
                                <span href="#">Publications</span>
                                <span href="#">Press Realease</span>
                                <span href="#">New Letters</span>

                            </div>
                        </div>
                        <div onClick={() => history.push('/contact-us')}>
                            <Icon icon={book} className="home" size={30}></Icon>
                            Contact us
                        </div>
                    </div>
                </div>
                <Icon icon={twitterSquare} onClick={() => window.open("https://twitter.com/?lang=en", "_blank")} className='twitter' size={50}></Icon>
                <Icon icon={facebookSquare} onClick={() => window.open("https://www.facebook.com/", "_blank")} className='facebook' size={50}></Icon>
                <Icon icon={linkedinSquare} onClick={() => window.open("https://in.linkedin.com/", "_blank")} className='linkedin' size={50}></Icon>

            </header>

            {/* <div className="img-slider">
                <div className="slider-container">

                    <div className="slide">
                        <img alt='' src="https://images.unsplash.com/photo-1598432439250-0330f9130e14?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" />
                    </div>

                    <div className="slide">
                        <img alt='' src="https://images.unsplash.com/photo-1597781725069-16d5ca74e6d4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" />
                    </div>

                    <div className="slide">
                        <img alt='' src="https://images.unsplash.com/photo-1565373679580-fc0cb538f49c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" />
                    </div>

                </div>
            </div> */}

            {switchRoutes}
            <Footer />
        </div>
    );
}

export default Header;
