import React from 'react';
import './loader.css'


const Loader = (props) =>{

    const {visible} = props

return(
    <React.Fragment>
        { visible &&
            <div style={{
                background: 'rgba(0,0,0,0.5)',
                zIndex:999,
                width: '100%',
                height: '100vh',
                position:'fixed',
                top:0,
                left:0,
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}>
               <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div> }
    </React.Fragment>
)


}

export default Loader;