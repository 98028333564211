

export const config = {
    'acc': 'Start-UPS',
    'aa': 'Implementation of IND-AS',
    'it': 'Taxation Services',
    'fm': "Financial Matters",
    'cl': 'Business and Advisory services',
    'rs': 'Legal Compliances'
}


export const ServiceData = {
    acc:
    {
        Subheading: '',
        value: [
            `Incorporation and Registration as Private limited, Limited Companies, Limited Liability Patnerships.`,
            `Registration of Liasion Office, Project Office and Branch Office of Foreign Companies`,
            `Compaliance with various laws of land in India`,
            `NRI investment and compliances`,
            `Investment outside India(Overseas direct investments) compliances`,
            `Registeration of the organisation as Start-up with Govt. of India`,
            `Compliances with Tax authoritites for Tax waivers in case of Start-ups`,
            `Private Equity / Share Funding / Long Term Finance for suitable projects`,
            `Registeration services with various authorities`,
            `Valuation of equity shares for funding requirement`
        ]
    },
    aa: {
        Subheading: 'The MCA has notified a phase-wise implemenation of IND-AS. They shall be adopted by specific classes of companies based of the net worth and listing status. Implementaion of IND-AS is likely to impact key performance areas that can have a wide ranging impact on companies financial and operating systems.',
        value: [
            `Review of historical financial historical data for Implementation of First time IND-AS`,
            `Primary assessment of business and selection of key IND-AS standards with an impact on financial statements of company`,
            `Selection of appropriate standards as IND-AS 101 which contains the transition provisions for entity to adopt IND-AS for the first time.`,
            `Final implementation of IND-AS through strategic assessments and proper audit plan`,
            `Compliance with various disclosure requirements of various standards.`
        ]
    },
    it: {
        Subheading: 'Our trained Chartered Accountants provide easy and hassle free ways to help manage tax compliances.',
        value: [
            {
                name: `Direct Tax compliances`,
                Subheading: '',
                value: [
                    `Tax consultancy together with innovative tax efficient strategies, provided by us form an integral part of viable business decisions. These help our clients attain the desired goals. We adopt a “result oriented approach” which is flexible and emphasizes delivery and value. It enhances the effect of commercially viable decisions and minimizes the tax burden.`,
                    `Representation services before revenue authorities and tax litigation support`,
                    `Opinion and certification under direct taxation.`,
                    `Advising on Income Tax Planning of Corporates`,
                    `Advising & reviewing of all necessary tax withholding responsibilities`,
                    `Verification of all payments to vendors for the purposes of determination of correct application of rates and category for deduction of withholding taxes`,
                    `Computation of monthly TDS on the basis of above`,
                    `Monthly tax reconciliation of the TDS due and deducted`,
                    `Preparation and deposit of Monthly challans on or before the statutory due dates`,
                    `Filing of quarterly E-TDS Returns`,
                    `Filing annual Income Tax return`,
                    `Issuance of Chartered Accountant certificates u/s 195 required for the Overseas Remittances purposes from time to time`,
                    `Preparation of Income Tax returns`,
                    `Assessment of Income Tax Returns`,
                    `Consultancy in Income Tax matters and planning`,
                    `Income Tax audit`,
                    `FORM 10 B Audit`,
                    `Ex patriate taxation`,
                    `International taxation consultancy`,
                    `TDS and withholding tax compliances`,
                    `Income tax appeals before commissioner of Income Tax`,
                    `Income tax appeals before Income Tax Appellate Tribunal`
                ]
            },
            {
                name: `Indirect Tax compliances (GST)`,
                Subheading: "",
                value: [
                    `Tax planning strategy - Optimum use of tax Incentives`,
                    `Advising the clients on applicability of GST`,
                    `Training the clients on the various aspects of tax.`,
                    `Opinion and certification under Indirect Taxation`,
                    `Compiling and calculating the net Goods & Service tax on output services after taking benefit of Input Tax Credits`,
                    `Compiling the data of Input Tax Credits for Goods & Service tax`,
                    `Preparing & Filing of Goods & Service tax`,
                    `Advising on the issues relating to Goods & Service tax`,
                    `Consultancy on the maintenance of prescribed records`,
                    `Tax Planning as regards the minimization of Goods & Service tax Liability`,
                    `GST registration`,
                    `Filing of monthly, quarterly and yearly GST returns`,
                    `Consultancy on GST returns`,
                    `Refund of GST`,
                    `GST AUDIT`
                ]
            }
        ]
    },
    cl: {
        Subheading: 'We provide assistance in formation of Indian or offshore organisations.',
        value: [
            `Formation of company in India-- Private limited, public limited, wholly owned subsidiary, one person company, Liability partnership.`,
            `Registration of trust society and patnerships`,
            `Registration of Liasion offices, Branch offices and Project offices in case of foreign companies`,
            `Maintenance and compliance of statutory records`,
            `Registration of various licenses with various authorities in India`,
            `Assisting in various foreign exchange trasactions.`,
            `Foreign direct investment (FDI) compliances.`,
            `Permissions and Approvals required for various transactions under RBI and FEMA.`,
            `External commercial borrowings (ECB)`,
            `Procedures and compliances`,
            `Work regarding Registrar of Companies and National Company Law Tribunal`,
            `Mergers and Acquisitions`,
            `Corporate Restructuring`,
            `Joint ventures and Strategic alliances`,
            `Valuation of equity shares`,
            `Opinion and certification under Business laws.`,
        ]
    },
    fm: {
        Subheading: '',
        value: [
            `Statutory and Tax Audits`,
            `Internal Audits`,
            `System Audits`,
            `Revenue Audits`,
            `Review of Financial statements`,
            `Audit Supports`,
            `Preparation an Implementation of Standard Operating Procedures(SOPs)`,
            `Business Advisory Services`,
            `Prepartion of Project reports and Agreemnents`,
            `Support services for negotiation with Financial Institution`,
            `Private Equity Capital/ Angel Funding for suitable projects`,
            `Preparation of various MIS`,
            `System and process control views`,
            `Due Deligence and special investigation audits`,
            `Physical verification of stocks, assests`,
            `Budgeting and cash flow planning`,
            `General accounting support as required by the client`,
            `Annual financial report prepartion and financial analysis`,
        ]
    },
    rs: {
        Subheading: '',
        value: [
            `Representation and litigation services before Direct tax authorities in India`,
            `Representation and litigation services before Indirect tax authorities in India`,
            `Setting up of Liasion office, Branch Offices and Project Offices of Foreign companies.`,
            `Prepartion of the search reports of companies for due Deligence, Purchase etc. for Banks / Financial Institutions and Private Investors`,
            `Liasioning and Coordination with the Registrar of companies, Regional Directors.`,
            `Representation and litigation services before NCLT and NCLAT in the matters related to Mergers and Aquisitions, Demergers, Amalgamations and Reconstruction of companies`,
            `Representation and litigation services before NCLT and NCLAT in the matter of Insolvancy whereby representing Corporate Debtors, Operational Creditors, Financial Creditors and Resolution Professionals.`,
            `Legal services with regards to Insolvancy Professionals, Liquidators and Resolution Applicant`,
            `Arranging of Joint-ventures, Acquisitions and Mergers as part of Resolution Plans`,
            `Representation and litigation services before Debt Recovery Tribunals in the matters of Sarfaesi Act`,
            `Providing Corporate fraud Investigation services and advisory in Anti-money laundering`,
            `Legal Services for specific assignments like obtaining various government approvals, SEBI approvals, written opinions and certifications`

        ]
    }

}
